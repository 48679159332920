import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";

export const LoggedGuard = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const keycloakService = inject(KeycloakService);

    const isLogged = keycloakService.isLoggedIn();
    const targetUrl = state.url;
    if (!isLogged) keycloakService.login({
        redirectUri: environment.keycloak.redirectUrl + targetUrl,
        locale: "fr",
        scope: "openid profile email",
    });
    return isLogged;
};
